.profile-page-wrapper{
    position: relative;
    width: 100vw;

    .inner-profile-page-wrapper{
        width: $inner-width-container-big;        
        margin: auto;
        display: flex;        
        padding-top:20px;
        flex-direction: row;
        justify-content: space-between;

        .left-sidebar-profile{
            width: 25%;
            border: 1px solid rgb(230, 230, 230);
            border-radius: $border-radius;
            padding: 20px;

            .short-desc-profile-wrapper{
                justify-content: flex-start;
                align-items: center;
                padding-bottom: 20px;
                border-bottom: 1px solid rgb(230, 230, 230);

                .profpic{
                    height: 50px;
                    width: 50px;
                    margin-right: 10px;
                }

                .name{
                    font-weight: 600;
                    color:$header-background;
                }
            }

            .menu-profile-side{
                margin-top: 20px;

                ul{
                    list-style: none;
                    margin: 0px;
                    padding: 0px;

                    li{
                        font-weight: 900;
                        color: $header-background;
                        line-height: 1.5;
                        cursor: pointer;
                        font-size: 14pt;
                        padding: 10px;

                        &:hover{
                            background: rgb(243, 243, 243);
                            border-radius: $border-radius;
                        }
                        
                    }

                    .logout-li{
                        margin-top:40px;
                    }
                }
            }
            
        }

        .main-profile-page{
            width: 73%;
            border: 1px solid rgb(230, 230, 230);
            border-radius: $border-radius;
            padding: 20px;
            min-height: 100vh;

        }

    }
}

.flex-row{
    display: flex;
    flex-direction: row;
}

.flex-column{
    display: flex;
    flex-direction: column
}

.flex-space-between{
    justify-content: space-between;
}

@media screen and (max-width:$break-medium){
    .profile-page-wrapper{
        
        .inner-profile-page-wrapper{
            width: 100%;  
            padding-top:0px;

            .left-sidebar-profile{
                display: none;
            }

            .main-profile-page{
                width: 100%;
            }
        }
    }
}
