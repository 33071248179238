.footer-wrapper{
    position: relative;
    width: 100vw;
    height: 320px;
    background: #f0f0f0;

    .inner-footer-wrapper{
        width: $inner-width-container-big;
        margin: auto;
        padding-top:30px;
        padding-bottom:30px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: top;

        .footer-column{
            width: 25%;

            h4{
                text-transform: uppercase;
                color: $header-background;
                position: relative;
                font-family: 'Poppins', sans-serif;

                .collapse-button-trigger{
                    display: none;
                }
            }

            .payment-logo{
                

                .footer-background-logo{
                    background-image: url('https://cdngarenanow-a.akamaihd.net/shopee/shopee-pcmall-live-id/assets/ed4e16c501919a270e9a38deb6603653.png');
                }

                ul{
                    text-decoration: none;
                    display: flex;
                    flex-direction: row;
                    li{
                        margin:3px;
                    }
                }
            }

            ul{
                list-style: none;
                margin: 0;
                padding: 0;

                li{
                    cursor: pointer;
                    color: grey;
                    line-height: 2;
                    font-size: 14px;

                    a{
                        text-decoration: none;
                        color: grey;
                    }
                }
            }
        }
    }
}

.footer-bank_mandiri-png {
    width: 50px;
    height: 29px;
    background-size: 2484% 686.2068965517242%;
    background-position: 7.130872483221476% 64.76470588235294%;
}

.footer-bca-png {
    width: 55px;
    height: 29px;
    background-size: 2258.181818181818% 686.2068965517242%;
    background-position: 24.85256950294861% 5.882352941176471%;
}

.footer-bni-png {
    width: 41px;
    height: 29px;
    background-size: 3029.268292682927% 686.2068965517242%;
    background-position: 30.80766028309742% 5.882352941176471%;
}

.footer-bank_bri-png {
    width: 55px;
    height: 17px;
    background-position: .8424599831508003% 60.588235294117645%;
}

.footer-jne-png {
    width: 55px;
    height: 17px;
    background-position: 58.38247683235046% 5.882352941176471%;
}

.footer-jnt-png {
    width: 55px;
    height: 17px;
    background-size: 2258.181818181818% 1170.5882352941176%;
    background-position: 100% 5.4945054945054945%;
}

.footer-gosend-png {
    width: 55px;
    height: 9px;
    background-size: 2258.181818181818% 2211.1111111111113%;
    background-position: 99.74726200505476% 43.68421052631579%;
}

.footer-pos_indo-png {
    background-position: 83.31929233361416% 5.882352941176471%;
}

@media screen and (max-width:$break-medium){
    .footer-wrapper{
        height: auto;

        .inner-footer-wrapper{
            width: $inner-width-container-medium;
            flex-direction: column;
            padding-top:10px;
            text-align: center;
            
            
            .footer-column{
                width: 100%;
                
                h4{
                    
                    .collapse-button-trigger{
                        display: inherit;
                        width: 100%;
                        position: absolute;
                        height: 40px;
                        // background: yellow;
                        cursor: pointer;
                        opacity: 0.3;
                        margin-top: -12px;
                        border-bottom: 2px solid #b5b1b1;
                    }
                }

                ul{
                    max-height: 0;
                    overflow: hidden;  
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    -webkit-transition: max-height 2s; /* For Safari 3.1 to 6.0 */
                    transition: max-height 0.2s;
                }

                
            }
        }
    }
}

.zero-height{
    max-height: 200px !important;    
      
}



  /* for desktop */
  .whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}