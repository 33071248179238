.purchase-list-wrapper{
    width: 100%;
    color: $bodyFontColor;

    .purchase-list-header{
        display: flex;
        align-items: center;

        .filter-label{
            padding-right: 20px;            
        }

        .filter-button-wrapper{            
            flex-direction: row;            
            position: relative;           

            button{
                background: white;
                padding: 10px 20px;
                font-size: 10pt;
                color: $bodyFontColor;
                border-radius: 24px;
                margin-right: 10px;
                outline: none;
                cursor: pointer;

                &:hover{
                    background: rgb(236, 236, 236);
                }
            }
        }
    }

    .purchase-list-body{
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .purchase-list-wrapper{
            display: flex;
            flex-direction: row;           
            border-radius: $border-radius;
            padding: 30px;
            border: 1px solid rgb(235, 235, 235);
            justify-content: space-between;
            margin-bottom: 20px;
            

            &:hover{
                cursor: pointer;
                box-shadow: $box-shadow;
            }

            .icon-list{
                color: $color-secondary;
                width: 40px;
                i{
                    padding: 10px;
                    border-radius: 50%;
                    border: 2px solid $color-secondary;
                }
            }

            .purchase-description{
                margin-left: 20px;
                margin-right: 20px;

                .no-pesanan{
                    font-weight: 700;
                    font-size: 14pt;

                }

                .number-detail{
                    display: flex;
                    flex-direction: row;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    font-size: 10pt;

                    .total{
                        padding-right: 10px;
                        border-right: 1px solid rgb(235, 235, 235);

                        strong{
                            color: $color-secondary;
                        }
                    }

                    .date{
                        padding-left: 10px;
                    }
                }

                .deadline{
                    margin: 10px 0px;
                    background: rgb(238, 238, 238);
                    padding: 20px;
                }

                .payment-method{
                    font-size: 10pt;
                }

            }

            .purchase-action{
                text-align: center;
                width: 20%;

                .label-status{
                    font-weight: 600;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    margin-bottom: 10px;  
                    color: $color-secondary;                  
                }

                .status{
                    font-size: 10pt;
                    background: white;
                    padding: 10px;
                    font-size: 10pt;
                    color: $bodyFontColor;
                    border-radius: 24px;                   
                    outline: none;
                    border:1px solid $color-secondary;

                }
            }
        }

    }
}

@media screen and (max-width:$break-small){
    .purchase-list-wrapper{
        width:100% !important;
        .purchase-list-header{

            .filter-button-wrapper{

                button{
                    font-size: 7pt;
                }
            }
        }  
        
        .purchase-list-body{

            .purchase-list-wrapper{
                flex-direction: column;
                padding:20px;

                .purchase-description{
                    margin:10px 0px;

                    .number-detail{
                        flex-direction: column;


                        .date{
                            padding: 0px;                            
                        }
                    }

                    .deadline{
                        margin: 20px 0px;
                        font-size: 8pt;
                    }
                }

                .purchase-action{
                    width: 50%;
                    .label-status{
                        text-align: left;
                    }

                    .status{
                        font-size: 8pt;
                    }
                }
            }
        }
    }
}