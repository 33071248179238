.backdrop{
    position: fixed;
    width:100vw;
    height: 100vh;
    background: black;
    opacity: 0.7;
    z-index: 99;
}   



.header-wrapper {
    position: fixed;
    right: 0;
    top:0;
    left: 0;
    background: $header-background;
    color:white;
    z-index: 100;
    height: $header-height;


    .inner-container {
        display: flex;
        flex-direction: row;
        width: $inner-width-container-big;
        margin: auto;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: relative;

        .logo{      
            width: 10%;   
            img{
                width: 80px
            }
        }

        .mobile-menu-button{
            display: none;

            .dropdown-category-mobile{
                display: none;
            }
        }

        .search-area{
            width: 60%;
            display: flex;   
            margin: 0px 20px; 
            
            
            .category-list{    
                width:30%;  
                display: flex;                
                .category-button{
                    width: 100%;
                }   
                
                .dropdown-category-website{
                    display: none;
                    position: absolute;
                    background: white;
                    top:100%;
                    color: rgb(59, 59, 59);
                    width: 200px;
                    border-radius: 0px 0px 8px 8px;
                    max-height: 70vh;
                    overflow: scroll;

                    ul {
                        margin: 0;
                        padding: 10px;
                        width: 100%;
                        // padding-top:0px;
                        li{
                            
                            text-decoration: none;
                            list-style: none;
                            padding: 8px;
                            cursor: pointer;
                            font-size: 10pt;
                            color: grey;

                            i{
                                position: absolute;
                                right: 20px;
                                color: #e4e1e1;
                            }

                            &:hover{
                                background:rgb(223, 223, 223);
                                color:black;
                                border-radius:0px 8px 8px 0px;
                            }

                            .dropdown-category-detail{
                                display: none;
                                position: absolute;
                                left: 100%;
                                top:0vh;
                                width: 530px;
                                min-height: 340px;
                                background: white;
                                border-radius: 0px 0px 8px 0px;
                            }
                        }
                    }
                }
            }


            .search-input{
                width: 70%;
                input{
                    height: 40px;
                    border: none;
                    width: 100%;
                    border-radius: $border-radius;
                    font-size: 16px;
                    padding-left: 10px;

                    &:focus {
                        outline: 0px;
                    }
                }
            }
        }

        .category-button, 
        .login-button,
        .cart-button{

            .count-in-cart-header{
                position: absolute;
                background: #f14848;
                color: white;
                left: 24px;
                top: 3px;
                font-size: 10pt;
                border-radius: 50%;
                width: 20px;
                height: 20px;
            }
                                      
            cursor: pointer;
            position: relative;
            display: flex;
            justify-content: center;            
            font-size:18px;
            background: none;
            color: white;
            border: none;
            font-family: 'Poppins', sans-serif;
            margin-top: 10px;

            &:focus {
                outline: 0px;
            }
               
            i {
                margin-left: 10px;
                font-size: 20px;
            }            
           
        }

        .cart-dropdown-detail {
            position: absolute;
            color:black;
            top: 100%;
            right: 5%;
            background: white;
            width: 400px;
            max-height: 400px;
            box-shadow:0 0 16px 0 rgba(0,0,0,.12), 0 16px 16px 0 rgba(0,0,0,.24);
            border: 1px solid darkgrey;
            padding: 10px;

            .cart-dropdown-header{
                max-height: 80px;
                text-align: center;
                // background: purple;
                padding: 10px 0px;
                font-size: 10pt;
                border-bottom: 1px solid #d8d8d8;
            }

            .cart-dropdown-body{
                max-height: 245px;
                overflow: scroll;
                // background: yellow;

                .cart-list-product-wrapper{
                    display: flex;
                    flex-direction: row;
                    align-content: space-between;
                    padding: 10px;
                    border-top: 1px solid #d8d8d8;
                    cursor: pointer;
                    position: relative;
                    

                    &:hover{
                        background: #f5f5f5;
                    }

                    .del-cart-sum{
                        position: absolute;                        
                        right: 10px;
                        top: 0;
                        padding: 5px 5px;

                        &:hover{
                            color:$color-secondary;
                        }
                       
                    }

                    .list-product-pict{
                        height: 100px;
                        width:100px;
                    }

                    .product-list-desc-wrapper{
                        padding: 10px;
                        color: $bodyFontColor;
                        max-width: 265px;
                        

                        .title{
                            font-weight: 600;
                            font-size: 11pt;
                        }

                        .category{
                            margin-top:5px;
                            font-size: 8pt;
                            color:rgb(163, 163, 163)
                        }

                        .price{
                            font-weight: 700;
                            font-size: 12pt;
                            color:black;
                        }
                    }
                }
            }
            
            .cart-dropdown-footer{
                height: 80px;
                border-top: 1px solid #d8d8d8;
                // background: green;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .cart-foot-wrapper{
                    .cd-foot-label-tot{
                        font-size: 10pt;
                    }

                    .cd-foot-label-num{
                        font-size: 18pt;
                        font-weight: 600;
                        color: $bodyFontColor;
                    }
                }

                .cart-foot-checkout-wrapper{
                    .cta-btn-checkout{                        
                        padding: 15px 15px;
                        cursor: pointer;
                        font-family: $body-font;
                        outline: none;
                        font-weight: 600;
                        border-radius: $border-radius;
                        border-right: none;
                        border-left: none;
                        border-top: none;
                        width: 100%;
                        text-align: center;
                        color: white;
                        font-size: 11pt;
                        background: $color-secondary;
    
                        
                        &:hover{
                            background: $color-secondary-light;
                        }
                       
                    }
                }
            }
        }

        .navigation-area{
            display: flex;
            width: 30%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 13px;

            .cart-button > i{
                font-size: 20pt;
                
            }

            .user-menu-wrapper{
                position: absolute;
                top:100%;
                background: white;
                color:$bodyFontColor;
                width: 200px;
                font-weight: 600;
                box-shadow: 0 0 16px 0 rgba(0,0,0,.12), 0 16px 16px 0 rgba(0,0,0,.24);

                ul{
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li{
                        padding: 10px 20px;
                        cursor: pointer;

                        &:hover{
                            background: rgb(231, 231, 231);
                        }

                        &:last-child{
                            margin-top:15px;
                        }
                    }
                }
            }
        }
    }
}

.header-bottom {
        position: fixed;  
        bottom: 0;       
        left: 0;
        width: 100%;
        background: rgb(30, 30, 60); 
        height: 50px;      
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 16px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        z-index: 101; 
    
}

//header perpusnas

.header-perpusnas {
    position: fixed;
    right: 0;
    top:0;
    left: 0;
    background: $header-background-perpusnas;
    color:white;
    z-index: 100;
    height: $header-height;



    .inner-container {
        display: flex;
        flex-direction: row;
        width: $inner-width-container-big;
        margin: auto;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: relative;

        .logo{      
            width: 10%;   
            img{
                width: 80px
            }
        }

        .mobile-menu-button{
            display: none;

            .dropdown-category-mobile{
                display: none;
            }
        }

        .search-area{
            width: 60%;
            display: flex;   
            margin: 0px 20px; 
            
            
            .category-list{    
                width:30%;  
                display: flex;                
                .category-button{
                    width: 100%;
                }   
                
                .dropdown-category-website{
                    display: none;
                    position: absolute;
                    background: white;
                    top:100%;
                    color: rgb(59, 59, 59);
                    width: 200px;
                    border-radius: 0px 0px 8px 8px;
                    max-height: 70vh;
                    overflow: scroll;

                    ul {
                        margin: 0;
                        padding: 10px;
                        width: 100%;
                        // padding-top:0px;
                        li{
                            
                            text-decoration: none;
                            list-style: none;
                            padding: 8px;
                            cursor: pointer;
                            font-size: 10pt;
                            color: grey;

                            i{
                                position: absolute;
                                right: 20px;
                                color: #e4e1e1;
                            }

                            &:hover{
                                background:rgb(223, 223, 223);
                                color:black;
                                border-radius:0px 8px 8px 0px;
                            }

                            .dropdown-category-detail{
                                display: none;
                                position: absolute;
                                left: 100%;
                                top:0vh;
                                width: 530px;
                                min-height: 340px;
                                background: white;
                                border-radius: 0px 0px 8px 0px;
                            }
                        }
                    }
                }
            }


            .search-input{
                width: 70%;
                input{
                    height: 40px;
                    border: none;
                    width: 100%;
                    border-radius: $border-radius;
                    font-size: 16px;
                    padding-left: 10px;

                    &:focus {
                        outline: 0px;
                    }
                }
            }
        }

        .category-button, 
        .login-button,
        .cart-button{

            .count-in-cart-header{
                position: absolute;
                background: #f14848;
                color: white;
                left: 24px;
                top: 3px;
                font-size: 10pt;
                border-radius: 50%;
                width: 20px;
                height: 20px;
            }
                                      
            cursor: pointer;
            position: relative;
            display: flex;
            justify-content: center;            
            font-size:18px;
            background: none;
            color: white;
            border: none;
            font-family: 'Poppins', sans-serif;

            &:focus {
                outline: 0px;
            }
               
            i {
                margin-left: 10px;
                font-size: 20px;
            }            
           
        }

        .cart-dropdown-detail {
            position: absolute;
            color:black;
            top: 100%;
            right: 5%;
            background: white;
            width: 400px;
            max-height: 400px;
            box-shadow:0 0 16px 0 rgba(0,0,0,.12), 0 16px 16px 0 rgba(0,0,0,.24);
            border: 1px solid darkgrey;
            padding: 10px;

            .cart-dropdown-header{
                max-height: 80px;
                text-align: center;
                // background: purple;
                padding: 10px 0px;
                font-size: 10pt;
                border-bottom: 1px solid #d8d8d8;
            }

            .cart-dropdown-body{
                max-height: 245px;
                overflow: scroll;
                // background: yellow;

                .cart-list-product-wrapper{
                    display: flex;
                    flex-direction: row;
                    align-content: space-between;
                    padding: 10px;
                    border-top: 1px solid #d8d8d8;
                    cursor: pointer;
                    position: relative;

                    &:hover{
                        background: #f5f5f5;
                    }

                    .del-cart-sum{
                        position: absolute;                        
                        right: 10px;
                        top: 0;
                        padding: 5px 5px;

                        &:hover{
                            color:$color-secondary;
                        }
                       
                    }

                    .list-product-pict{
                        height: 100px;
                        width:100px;
                    }

                    .product-list-desc-wrapper{
                        padding: 10px;
                        color: $bodyFontColor;
                        max-width: 265px;
                        

                        .title{
                            font-weight: 600;
                            font-size: 11pt;
                        }

                        .category{
                            margin-top:5px;
                            font-size: 8pt;
                            color:rgb(163, 163, 163)
                        }

                        .price{
                            font-weight: 700;
                            font-size: 12pt;
                            color:black;
                        }
                    }
                }
            }
            
            .cart-dropdown-footer{
                height: 80px;
                border-top: 1px solid #d8d8d8;
                // background: green;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .cart-foot-wrapper{
                    .cd-foot-label-tot{
                        font-size: 10pt;
                    }

                    .cd-foot-label-num{
                        font-size: 18pt;
                        font-weight: 600;
                        color: $bodyFontColor;
                    }
                }

                .cart-foot-checkout-wrapper{
                    .cta-btn-checkout{                        
                        padding: 15px 15px;
                        cursor: pointer;
                        font-family: $body-font;
                        outline: none;
                        font-weight: 600;
                        border-radius: $border-radius;
                        border-right: none;
                        border-left: none;
                        border-top: none;
                        width: 100%;
                        text-align: center;
                        color: white;
                        font-size: 11pt;
                        background: $color-secondary;
    
                        
                        &:hover{
                            background: $color-secondary-light;
                        }
                       
                    }
                }
            }
        }

        .navigation-area{
            display: flex;
            width: 30%;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .cart-button > i{
                font-size: 20pt;
            }

            .user-menu-wrapper{
                position: absolute;
                top:100%;
                background: white;
                color:$bodyFontColor;
                width: 200px;
                font-weight: 600;
                box-shadow: 0 0 16px 0 rgba(0,0,0,.12), 0 16px 16px 0 rgba(0,0,0,.24);

                ul{
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li{
                        padding: 10px 20px;
                        cursor: pointer;

                        &:hover{
                            background: rgb(231, 231, 231);
                        }

                        &:last-child{
                            margin-top:15px;
                        }
                    }
                }
            }
        }
    }
}

// Responsive 
@media screen and (max-width:$break-small) {
.header-wrapper{
    
    .inner-container {
        width: $inner-width-container-small ;
        display: flex;   
        position: relative;
        
        .logo{      
            position: absolute;           
            left: 16vw;            
            a{                
                img{
                    width: 40px
                }
            }
        }       

        
        
        .search-area{
            width: 80%;
            margin: 0;
            
            .category-list{
                display: none ;            
            }
    
            .search-input{
                width: 100% ;
                input{
                    padding-left:14vw;
                   
                }
            }
        }

        .cart-dropdown-detail {
            right: 0px;
            position: fixed;
            top: $header-height;
            width: 100%;
        }
    
        .navigation-area{
            order: 2;
            width: 10%;
            margin: 0px 3px;
            position: relative;
            
            .login-button{
                display: none;
            }

           

            // .cart-button{
                
            // }
        }
    
    }
}

.header-perpusnas{
    
    .inner-container {
        width: $inner-width-container-small ;
        display: flex;   
        position: relative;
        
        .logo{      
            position: absolute;           
            left: 16vw;            
            a{                
                img{
                    width: 40px
                }
            }
        }       
     
        .search-area{
            width: 80%;
            margin: 0;
            
            .category-list{
                display: none ;            
            }
    
            .search-input{
                width: 100% ;
                input{
                    padding-left:14vw;
                   
                }
            }
        }

        .cart-dropdown-detail {
            right: 0px;
            position: fixed;
            top: $header-height;
            width: 100%;
        }
    
        .navigation-area{
            order: 2;
            width: 10%;
            margin: 0px 3px;
            position: relative;
            
            .login-button{
                display: none;
            }

           

            // .cart-button{
                
            // }
        }
    
    }
}

// $inner-width-container-small: 80%;
// $header-height: 60px;



}

    .open-nav-website{
        display: inherit !important;
    }


@media screen and (max-width: $break-medium){
    
    .open-nav-website{
        display: none;
    }

    .open-nav{
        width: 100% !important;
    }

    .header-wrapper{

        
        .inner-container{
            width: $inner-width-container-medium;            ;

            .logo{
                width: 20%;   
            }

            .mobile-menu-button{
                margin: 0px 10px;
                display: inline;
                font-size: 20pt;
                cursor: pointer;
                

                .dropdown-category-mobile, 
                .dropdown-category-detail{ 
                    color:black;
                    display: inherit;               
                    position: fixed;
                    top: 65px;
                    overflow: scroll;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: #fff;
                    z-index: 1;
                    line-height: 42px;
                    font-weight: 400;
                    width: 0;
                    max-width: 100%;
                    min-height: 0;                    
                    font-size: 10pt;
                    -webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
                    transition: 0.2s;
    
                    ul{
                        list-style: none;
                        padding: 10px;
                        margin: 0;
                        width: 100%;

                        li{
                            display: flex;
                            border-bottom: 1px solid #e8eaf6;
                            padding: 8px 20px;
                            font-size: 12pt;
                            align-items: center;
                            color:grey;
                            cursor: pointer;
    
                            i{
                                position: absolute;
                                right: 20px;
                                color: #e4e1e1;
                            }
    
                            .dropdown-category-detail{
                                width: 0;                                
                                cursor: pointer;
                                // background:pink;
                            }
                        }
                    }
                }
            }

            

            .search-area {                
                margin: 0px 10px;
                .category-list{
                    display: none;
                }

                .search-input{
                    width:100%;
                }
            }

            .cart-dropdown-detail {
                right: 0px;
            }
            

            .navigation-area{
                order: 2;
                width: 10%;
                margin: 0px 3px;
                
                .login-button{
                    display: none;
                }
    
                .cart-button{
                    margin: 0px 10px;
                }

                    
                // }
            }
        

        }
    }
}

//header imprint
.header-imprint {
    position: fixed;
    right: 0;
    top:0;
    left: 0;
    background: $header-background-imprint;
    color:white;
    z-index: 100;
    height: $header-height;
    display: flex;

    .inner-container {
        display: flex;
        flex-direction: row;
        width: $inner-width-container-big;
        margin: auto;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: relative;

        .logo{      
            width: 10%; 
            display: flex; 
            gap: 5px;  
            
            .logo-ipb{
                width: 80px;
                height: auto;
                margin-top: 30px;
               
            }
            .separator{
                font-size: 24px;
                font-weight: bold;
                color: rgb(254, 254, 254);
                margin-top: 30px;
                margin-left: 10px;
        
            }
            .logo-imprint {
                width: 110px; 
                height: auto;
            }
                    
        }
        


        .mobile-menu-button{
            display: none;

            .dropdown-category-mobile{
                display: none;
            }
        }

        .search-area{
            width: 60%;
            display: flex;   
            margin: 0px 20px; 
            
            
            .category-list{    
                width:30%;  
                display: flex;                
                .category-button{
                    width: 100%;
                    margin-left: 120px ;
                    margin-top: 12px;
                }   
                
                .dropdown-category-website{
                    display: none;
                    position: absolute;
                    background: white;
                    top:100%;
                    color: rgb(59, 59, 59);
                    width: 200px;
                    border-radius: 0px 0px 8px 8px;
                    max-height: 70vh;
                    overflow: scroll;
                    margin-right: 100px;
                    ul {
                        margin: 0;
                        padding: 10px;
                        width: 100%;
                        // padding-top:0px;
                        li{
                            
                            text-decoration: none;
                            list-style: none;
                            padding: 8px;
                            cursor: pointer;
                            font-size: 10pt;
                            color: grey;


                            i{
                                position: absolute;
                                right: 20px;
                                color: #e4e1e1;
                            }

                            &:hover{
                                background:rgb(223, 223, 223);
                                color:black;
                                border-radius:0px 8px 8px 0px;
                            }

                            .dropdown-category-detail{
                                display: none;
                                position: absolute;
                                left: 100%;
                                top:0vh;
                                width: 530px;
                                min-height: 340px;
                                background: white;
                                border-radius: 0px 0px 8px 0px;
                            }
                        }
                    }
                }
            }


            .search-input{
                width: 70%;
                input{
                    height: 40px;
                    border: none;
                    width: 100%;
                    border-radius: $border-radius;
                    font-size: 16px;
                    padding-left: 10px;
                    margin-left: 60px;

                    &:focus {
                        outline: 0px;
                    }
                }
            }
        }

        .category-button, 
        .login-button,
        .cart-button{

            .count-in-cart-header{
                position: absolute;
                background: #f14848;
                color: white;
                left: 24px;
                top: 3px;
                font-size: 10pt;
                border-radius: 50%;
                width: 20px;
                height: 20px;
            }
                                      
            cursor: pointer;
            position: relative;
            display: flex;
            justify-content: center;            
            font-size:18px;
            background: none;
            color: white;
            border: none;
            font-family: 'Poppins', sans-serif;

            &:focus {
                outline: 0px;
            }
               
            i {
                margin-left: 10px;
                font-size: 20px;
            }            
           
        }

        .cart-dropdown-detail {
            position: absolute;
            color:black;
            top: 100%;
            right: 5%;
            background: white;
            width: 400px;
            max-height: 400px;
            box-shadow:0 0 16px 0 rgba(0,0,0,.12), 0 16px 16px 0 rgba(0,0,0,.24);
            border: 1px solid darkgrey;
            padding: 10px;

            .cart-dropdown-header{
                max-height: 80px;
                text-align: center;
                // background: purple;
                padding: 10px 0px;
                font-size: 10pt;
                border-bottom: 1px solid #d8d8d8;
            }

            .cart-dropdown-body{
                max-height: 245px;
                overflow: scroll;
                // background: yellow;

                .cart-list-product-wrapper{
                    display: flex;
                    flex-direction: row;
                    align-content: space-between;
                    padding: 10px;
                    border-top: 1px solid #d8d8d8;
                    cursor: pointer;
                    position: relative;

                    &:hover{
                        background: #f5f5f5;
                    }

                    .del-cart-sum{
                        position: absolute;                        
                        right: 10px;
                        top: 0;
                        padding: 5px 5px;

                        &:hover{
                            color:$color-secondary;
                        }
                       
                    }

                    .list-product-pict{
                        height: 100px;
                        width:100px;
                    }

                    .product-list-desc-wrapper{
                        padding: 10px;
                        color: $bodyFontColor;
                        max-width: 265px;
                        

                        .title{
                            font-weight: 600;
                            font-size: 11pt;
                        }

                        .category{
                            margin-top:5px;
                            font-size: 8pt;
                            color:rgb(163, 163, 163)
                        }

                        .price{
                            font-weight: 700;
                            font-size: 12pt;
                            color:black;
                        }
                    }
                }
            }
            
            .cart-dropdown-footer{
                height: 80px;
                border-top: 1px solid #d8d8d8;
                // background: green;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .cart-foot-wrapper{
                    .cd-foot-label-tot{
                        font-size: 10pt;
                    }

                    .cd-foot-label-num{
                        font-size: 18pt;
                        font-weight: 600;
                        color: $bodyFontColor;
                    }
                }

                .cart-foot-checkout-wrapper{
                    .cta-btn-checkout{                        
                        padding: 15px 15px;
                        cursor: pointer;
                        font-family: $body-font;
                        outline: none;
                        font-weight: 600;
                        border-radius: $border-radius;
                        border-right: none;
                        border-left: none;
                        border-top: none;
                        width: 100%;
                        text-align: center;
                        color: white;
                        font-size: 11pt;
                        background: $color-secondary;
    
                        
                        &:hover{
                            background: $color-secondary-light;
                        }
                       
                    }
                }
            }
        }

        .navigation-area{
            display: flex;
            width: 30%;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .cart-button > i{
                font-size: 20pt;
            }

            .user-menu-wrapper{
                position: absolute;
                top:100%;
                background: white;
                color:$bodyFontColor;
                width: 200px;
                font-weight: 600;
                box-shadow: 0 0 16px 0 rgba(0,0,0,.12), 0 16px 16px 0 rgba(0,0,0,.24);

                ul{
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li{
                        padding: 10px 20px;
                        cursor: pointer;

                        &:hover{
                            background: rgb(231, 231, 231);
                        }

                        &:last-child{
                            margin-top:15px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: $break-medium){
    
    .open-nav-website{
        display: none;
    }

    .open-nav{
        width: 100% !important;
    }

    .header-imprint{
        
        .inner-container{
            width: $inner-width-container-medium; 
              

            .logo{
                display: flex;
                width: 10%;
                align-items: start;
                
                .logo-ipb{
                    display: none;
                }
                .separator {
                    display: none; 
                }
                .logo-imprint{
                    width: 90px;
                    justify-content: flex-start ;
                    
                }
                   
            }

            .mobile-menu-button{
                display: inline;
                font-size: 20pt;
                cursor: pointer;
                margin-left: 50px;
                
              
                
                

                .dropdown-category-mobile, 
                .dropdown-category-detail{ 
                    color:black;
                    display: inherit;               
                    position: fixed;
                    top: 65px;
                    overflow: scroll;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: #fff;
                    z-index: 1;
                    line-height: 42px;
                    font-weight: 400;
                    width: 0;
                    max-width: 100%;
                    min-height: 0;                    
                    font-size: 10pt;
                    -webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
                    transition: 0.2s;
    
                    ul{
                        list-style: none;
                        padding: 10px;
                        margin: 0;
                        width: 100%;

                        li{
                            display: flex;
                            border-bottom: 1px solid #e8eaf6;
                            padding: 8px 20px;
                            font-size: 12pt;
                            align-items: center;
                            color:grey;
                            cursor: pointer;
    
                            i{
                                position: absolute;
                                right: 20px;
                                color: #e4e1e1;
                            }
    
                            .dropdown-category-detail{
                                width: 0;                                
                                cursor: pointer;
                                
                            }
                        }
                    }
                }
            }

            

            .search-area {                
                flex-grow: 1;
                display: flex;
                max-width: 400px;
                margin-right: 80px;


                .category-list{
                    display: none;
                }

                .search-input{
                    flex-grow: 1;
                    min-width: 150px;
                    max-width: 100%;
                }
            }

            .cart-dropdown-detail {
                right: 0px;
            }
            

            .navigation-area{
                order: 2;
                width: 10%;
                margin: 0px 3px;
                
                .login-button{
                    display: none;
                }
    
                // .cart-button{
                    
                // }
            }
        

        }
    }
}
@media screen and (max-width: $break-small){
    
    .open-nav-website{
        display: none;
    }

    .open-nav{
        width: 100% !important;
    }

    .header-imprint{
        
        .inner-container{
            width: $inner-width-container-small;
            display: flex;   
            justify-content: space-around; 
            width: 100%;
            align-items: center;
            padding-right: 10px;
              
            .logo{
                position: relative;
                align-items: start;
                left: 0.05vw;
                
                .logo-ipb{
                    display: none;
                }
                .separator {
                    display: none; 
                }
                .logo-imprint{
                    width: 90px;
                    
                    
                }
                   
            }

            .mobile-menu-button{
                position: relative;
                left: 50px;
                flex: 1;
                margin-left: 10px;
                
                         
                .dropdown-category-mobile, 
                .dropdown-category-detail{ 
                    color:black;
                    display: inherit;               
                    position: fixed;
                    top: 65px;
                    overflow: scroll;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: #fff;
                    z-index: 1;
                    line-height: 42px;
                    font-weight: 400;
                    width: 0;
                    max-width: 100%;
                    min-height: 0;                    
                    font-size: 10pt;
                    -webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
                    transition: 0.2s;
    
                    ul{
                        list-style: none;
                        padding: 10px;
                        margin: 0;
                        width: 100%;

                        li{
                            display: flex;
                            border-bottom: 1px solid #e8eaf6;
                            padding: 8px 20px;
                            font-size: 12pt;
                            align-items: center;
                            color:grey;
                            cursor: pointer;
    
                            i{
                                position: absolute;
                                right: 20px;
                                color: #e4e1e1;
                            }
    
                            .dropdown-category-detail{
                                width: 0;                                
                                cursor: pointer;
                                // background:pink;
                            }
                        }
                    }
                }
            }

            

            .search-area {  
                flex-grow: 0; 
                width: auto;
                display: flex;             
                justify-content: center;
                max-width: 50%;
                
                .category-list{
                    display: none;
                }

                .search-input{
                    width: 100%;

                    
                }
            }

            .cart-dropdown-detail {
                position: relative;
                right: 0;
                margin-right: 10px !important;
            }
            

            .navigation-area{
                order: 2;
                width: 10%;
                margin: 0px 3px;
                
                .login-button{
                    display: none;
                }
    
                // .cart-button{
                    
                // }
            }
        

        }
    }
}