$break-small: 480px;
$break-medium: 768px;
$break-big: 1024px;
$poppins-font: 'Poppins', sans-serif;
$body-font: 'Poppins', sans-serif;

$inner-width-container-small: 90%;
$inner-width-container-medium:93%;
$inner-width-container-big: 95%;

$border-radius: 5px;

$header-background : hsl(240, 100%, 17%);
$header-background-light : hsl(219, 86%, 44%);
$header-background-dark : hsl(240, 100%, 5%);
$header-background-perpusnas: #828282;
$header-background-imprint: #229799; 




$color-secondary: #f78200;
$color-secondary-light: #ffa238;
$color-secondary-dark: #ad5c00;
$grey-color-background: rgb(241, 241, 241);
$bodyFontColor : #505050;

$attention-color: #67b938;
$attention-color-light: #7ccc4e;
$attention-color-dark: #397c13;

$box-shadow : 0 0 1px #bbb, 0 1px 2px #f5f5f5; 
$header-height:65px;   