.invoice-wrapper{
    // background: grey;
    width: 815px;
    margin: auto;
    padding: 25px 32px;

    .invoice-detail{
        
    }
}

@media print {
    .no-print { display: none; }
}