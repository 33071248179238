.product-detail-wrapper{
    display: flex;
    flex-direction: column; 
    justify-self: center;
    align-items: center;
    gap: 20px;
    margin: auto;
    
    width: 100%;
    max-width: 1000px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    align-self: center;
    

    .inner-product-detail{
        width: 100%;
        max-width: 1000px;
        display: flex;
        justify-content: flex-start; 
        gap: 20px;
       
        

        .product-image-wrapper{
            width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-top:30px; 
            gap: 10px;
            

            .image-nav{
                display: flex;
                gap: 25px;
                ul{
                    display: flex;
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li{
                        margin-bottom: 5px; 
                        cursor: pointer;                       

                        .thbn-prod{
                            height: 100px;
                            width: 100px;
                        }
                    }
                }
            }

            .viewers-image{
                width: 100% !important; 
                max-width: 450px !important; 
                height: 500px !important; 
                max-height: 500px !important; 
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                overflow: hidden;
    
                

                .nav-button {
                    width: 30px; 
                    height: 30px;
                    border-radius: 50%; 
                    background-color: rgba(0, 0, 0, 0.5); 
                    color: white;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    cursor: pointer;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 10;
                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.7); // Warna lebih gelap saat hover
                    }
            
                    &.left {
                        left: 10px;
                       
                    }
            
                    &.right {
                        right: 6px;
                        top: 245px; 
                    }
                              
                }
                .nav-button .arrow::before {
                    color: white; 
                    font-size: 20px; 
                }
                
                .arrow::before {
                    content: "\276E"; 
                }
                
                .nav-button.right .arrow::before {
                    content: "\276F" !important; 
                }
                
                      
            }

        }
            
        .ptw{
            display: flex;
            flex-direction: column;
            min-width: 375px;
            max-width: 375px;
            margin-right: 80px;
            

            // Judul
            .pdh{
                
                h1{     
                    font-size: 30px;
                    letter-spacing: normal;
                    font-weight: 700;
                    line-height: 36px; 
                                      
                }
              
            }
            
            //author
            .pd-author{
                margin-top:1rem;
                margin-bottom: 20px;
                display: flex;
                align-items: flex-start;
                gap: 0.5rem;
                
                .label{
                    font-weight: 2rem;
                    margin-right: 5px;
                    font-size: 15px;
                    white-space: nowrap;
                }
                .name-container{
                    display: grid;
                    grid-template-columns: repeat(2, minmax(200px, 1fr)); 
                    width: 100%;

                    .name {
                        color: #232323; 
                        font-size: 15px;
                        
                    }

                }
                

            }
            .pd-author-jurnal{
                .top-jurnal {
                    margin-top: 1rem;
                    margin-bottom: 25px;

                    display: flex;
                    align-items: center;
                    gap: 0.5rem; 
                    
                    .title {
                        font-weight: 2rem;
                        font-size: 15px; 
                        white-space: nowrap; //biar ngga kebawah
                    }
                    
                    .publisher {
                        color: #232323;
                        margin-left: 10px; 
                        font-size: 15px;
                        grid-template-columns: repeat(2, minmax(200px, 1fr));
                    }
                }
                

            }
            .show-more-authors {
                background: none;
                border: none;
                color: hsl(0, 0%, 55%);
                cursor: pointer;
                font-size: 12px;
                align-items: center;
                text-align: left;
                margin: 0;
                padding: 0;
                
                
            }
          

            // Penialaian

            .spdh{
                display:flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                .pnln-rtg{
                    display: flex;
                    flex-direction: row;
                    color: $color-secondary;
                    border-right: 1px solid grey;
                    padding-right: 10px;
                }

                .pnln-ct{
                    padding-left:10px;
                    padding-right:10px;
                }
            }

            // Harga
            .prc-ctn{
                margin-top:2px;
                width: 100%;
                padding: 5px 0px;
                margin-bottom: 1rem;
                text-align: left;
                
                .prc-wrp{
                    padding: 2px 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;

                    .product-price{
                        display: flex;
                        font-size: 2.2rem;
                        font-weight: 700;
                        color: $color-secondary;
                        font-weight: 700;

                        .cta-penerbit {
                            color: #229799 !important; 
                            font-weight: bold;
                        }
                        
    
                    }
                    .product-price-imprint{
                        display: flex;
                        font-size: 2.2rem;
                        font-weight: 700;
                        color: #229799;
                        font-weight: 700;
    
                    }

                    .product-price__original{
                        font-size: 2.2rem;
                        color:  #f78200;
                        font-weight: 700;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 10px;
                        justify-content: flex-start;
                    }


                    .product-price__reduced{   
                        display: flex;  
                        align-items: center;
                        gap: 5px;      

                        .ori-price{
                            text-decoration: line-through;
                            // margin-right: 15px;
                            font-size: 17px;
                            color: grey;    
                                                                
                        }
                    }

                    .ppd-off{
                        padding: 2px 6px;
                        background: hsla(219, 34%, 31%, 0.558);
                        color:#ffffff;
                        font-size: 17px;
                        margin-left: 10px;
                        font-weight: 600;
                        text-transform: uppercase;
                        border-radius: 8px;
                        margin-bottom: 10px;
                    
                    }
                
                }
            }

            // Button 
            .cta-wrapper{
                margin-top: 0px;
                margin-bottom: 0px;
                
                
                .cta-btn{
                    margin-top: 20px;
                    padding: 10px 12px;
                    cursor: pointer;
                    font-family: $body-font;
                    outline: none;
                    border-radius: $border-radius;
                    border-right: none;
                    border-left: none;
                    border-top:none;      
                    width: 80%;
                    text-align: center;
                    color:rgb(255, 255, 255);
                    font-size: 13pt;
                    background:  $color-secondary;
                    border-bottom: 4px hsl(240, 1%, 27%);
                    transition: all 0.2s ease-in-out;
                    
                    &:hover{
                        background: $color-secondary-light;
                        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
                        transform: translateY(-2px);
                    }

                    }
                    .cta-penerbit{
                        margin-top: 20px;
                        padding: 10px 12px;
                        cursor: pointer;
                        font-family: $body-font;
                        outline: none;
                        border-radius: $border-radius;
                        border-right: none;
                        border-left: none;
                        border-top:none;      
                        width: 80%;
                        text-align: center;
                        color:rgb(255, 255, 255);
                        font-size: 13pt;
                        background:  #229799;
                        border-bottom: 4px hsl(240, 1%, 27%);
                        transition: all 0.2s ease-in-out;

                        &:hover{
                            background: $color-secondary-light;
                            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
                            transform: translateY(-2px);
                        }
                            
                        

                    // &:active{
                    //     border-bottom: 0px solid $color-secondary;         
                    //     transform: translateY(4px);   
                    //     margin-top:10px;                        
                    // }    
                }

                
                .cta-btn-jurnal{
                    padding: 10px 16px;
                    cursor: pointer;
                    font-family: $body-font;
                    outline: none;
                    border-radius: $border-radius;
                    border-right: none;
                    border-left: none;
                    border-top:none;      
                    width: 100%;
                    text-align: center;
                    color:white;
                    font-size: 10pt;
                    background: $color-secondary;
                    border-bottom: 4px solid hsl(0, 0%, 73%); 
                    margin-top: 10px;

                    
                    &:hover{
                        background: $color-secondary-light;
                        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
                        transform: translateY(-2px);

                    }
                    .prc-ctn {
                        margin-bottom: 10px; // Jarak antara harga dan tombol
                        flex-direction: column;
                    }

                    // &:active{
                    //     border-bottom: 0px solid $color-secondary;         
                    //     transform: translateY(4px);   
                    //     margin-top:10px;                        
                    // }    
                }
                

            .kerjasama-wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px; 
            
                .pd-author {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr); 
                    gap: 40px; 
                    align-items: start; 
                }
            
                .info-row {
                    display: flex;
                    justify-content: space-between;
                    gap: 80px; 
                }
            
                .title {
                    font-weight: bold;
                    margin-bottom: 4px;
                }  
}
       

              // untuk Update Quantity
                .qty-rwp{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin: 0;
                    padding: 0;
                    

                    .hdl-prod-wrp{   
                        display: flex;
                        flex-direction: row;  
                        align-items: center;
                                         

                        .chg-qty-btn{
                            padding: 10px 15px;
                            border: 1px solid grey;
                            // border-radius: $border-radius;                            
                            cursor: pointer;
                            margin: 0px 5px; 
                            height: 45px; 
                            
                            &:hover {
                                background: #f5f5f5;
                            }
                        }

                        .plus{
                            border-radius: 0px $border-radius $border-radius 0px;
                            margin-left: 0px;
                            border-left: none;
                        }

                        .minus{
                            border-radius:  $border-radius 0px 0px $border-radius;
                            margin-right: 0px;
                            border-right: none;
                        }
    
                        .input-chg-qty{
                            padding: 10px;
                            text-align: center;
                            font-size: 12pt;
                            font-weight: 500;
                            max-width: 65px;
                            border-radius: 0px;
                            outline: none;
                            border: 1px solid gray;
                        }
                    }

                    .cta-btn-update{
                        padding: 10px 20px;
                        cursor: pointer;
                        font-family: $body-font;
                        outline: none;
                        border-radius: $border-radius;
                        border-right: none;
                        border-left: none;
                        border-top:none;      
                        // width: 100%;
                        text-align: center;
                        color:white;
                        font-size: 15pt;
                        background: $attention-color;
                        border-bottom: solid gray;
                              

                        
                        &:hover{
                            background: $color-secondary-light;
                            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
                            transform: translateY(-2px);
                  
                        }

                        // &:active:enabled{
                        //     border-bottom: 0px solid $color-secondary;         
                        //     transform: translateY(4px);   
                        //     margin-top:10px;                        
                        // } 
                        
                        &:disabled{                           
                            background: $attention-color;
                            border-bottom: 0px;
                            opacity: 0.3;
                            
                        }
                    }
                }

    
            }

            // Lanjut Pembayaran
            .checkout-wrapper{
                margin-top:35px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;     
                width: 100%;
                
                .cta-btn-checkout{
                    margin-top: 20px;
                    padding: 10px 12px;
                    cursor: pointer;
                    font-family: $body-font;
                    outline: none; 
                    border-radius: $border-radius;
                    border-right: none;
                    border-left: none;
                    border-top:none;      
                    width: 100%;
                    text-align: center;
                    color:white;
                    font-size: 13pt;
                    background: $color-secondary;
                    box-shadow: none;
                    border-bottom: none !important;

                    
                    &:hover{
                        background: $color-secondary-light;
                        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
                        transform: translateY(-2px);

                    }
                   
                }
                .cta-penerbit{
                    margin-top: 20px;
                    padding: 10px 12px;
                    cursor: pointer;
                    font-family: $body-font;
                    outline: none; 
                    border-radius: $border-radius;
                    border-right: none;
                    border-left: none;
                    border-top:none;      
                    width: 100%;
                    text-align: center;
                    color:white;
                    font-size: 13pt;
                     
    
    
                    
                    &:hover{
                        background: $color-secondary-light;
                        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
                        transform: translateY(-2px);
    
                    }
                   
    
                }
                
                
                .orback{
                    margin-top:25px;

                    strong{
                        color:$color-secondary;
                    }
                }
                .cond-and-term{
                    font-size: 12px;
                    color: $color-secondary;
                    text-align: start;
                    margin-top: 0;
                }
            }
            
        }

        // Deskripsi bagian bawah
        .pdesc-wrapper{
            display: flex;
            flex-direction: row;
            width: 100%;
            
            .pd-phy{
                width:50%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;
                text-align: left;
                // text-transform: capitalize;   
                
                .pdescw{
                    padding: 10px;
                    flex: 1 0 25%;

                    .ptitle{
                        font-size: 11pt;
                        color: hsla(219, 34%, 31%, 0.558);
                    }
    
                    .pvalue{
                        font-weight: 700;
                        font-size: 15pt;
                    }
                }

            }

            .pd-desc{
                width: 65%;
                text-align: justify;
                padding: 20px;
                padding-right: 20px;
                margin-left: 50px;

                .title{
                    padding-bottom: 10px;
                    border-bottom: 1px solid whitesmoke;
                    font-size: 14pt;
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    line-height: 1.5;
                }
            
                button {
                    background: none;
                    border: none;
                    color: hsl(0, 0%, 55%);
                    cursor: pointer;
                    padding-left: 0px;
                    font-size: 12px;
            
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

        }
    }
}

.contain-pict{
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.zoom-ipbpress{
    background-position-y: 0% !important;
    background-position-x: 90% !important;
    background-size: 120% auto !important;
}


@media screen and (max-width: $break-medium) {
    .product-detail-wrapper {
        width: 100%;
        max-width: 1000px;
        
        .inner-product-detail {
            flex-direction: column;
            align-items: center;

            .product-image-wrapper {
                .image-nav {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;       
                    
                    ul {
                        flex-wrap: wrap;
                        justify-content: center;
                        
                        li {
                            margin-right: 5px;
                            
                            .thbn-prod {
                                height: 70px;
                                width: 70px;
                            }
                        }
                    }
                }

                .viewers-image {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    min-width: 350px;
                    min-height: 350px;
                    margin: 0 auto;
                }
                
                .nav-button {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba(0, 0, 0, 0.5);
                    border: none;
                    padding: 10px;
                    border-radius: 50%;
                    cursor: pointer;
                    z-index: 10;
                
                    &:hover {
                        background: rgba(0, 0, 0, 0.7);
                    }
                
                    &.left {
                        left: -30px;
                    }
                
                    &.right {
                        right: -30px;
                    }
                }
                
            }

            .ptw {
                min-width: auto;
                max-width: 100%;
                margin-right: 0;
                padding: 0 10px;

                .pdh h1 {
                    font-size: 24px;
                    line-height: 30px;
                }

                .pd-author,
                .pd-author-jurnal {
                    flex-direction: column;
                    align-items: flex-start;

                    .name-container {
                        grid-template-columns: repeat(1, minmax(150px, 1fr));
                    }
                }

                .prc-ctn {
                    text-align: center;
                    .prc-wrp{
                        margin-left: 0px;
                    }
                }

                .cta-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .cta-btn {
                        width: 100%;
                        padding: 8px 12px;
                    }

                    .qty-rwp {
                        flex-direction: column;
                        align-items: center;
                        width: 100%;

                        .hdl-prod-wrp {
                            justify-content: center;
                        }
                    }
                }
            }

            .pdesc-wrapper {
                flex-direction: column;
                align-items: center;
                text-align: center;

                .pd-phy,
                .pd-desc {
                    width: 100%;
                    margin: 0;
                    margin-right: 10px;
                    
                }
            }
        }
    }
}

@media screen and (max-width: $break-small) {
    .product-detail-wrapper {
        max-width: 100%;
        padding: 10px;
        overflow-x: auto;

        .inner-product-detail {
            .product-image-wrapper {
                .image-nav {
                    ul {
                        li {
                            .thbn-prod {
                                height: 60px;
                                width: 60px;
                            }
                        }
                    }
                }

                .viewers-image {
                    min-width: 200px;
                    min-height: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    margin-right: 20px !important;
                }
                
                .nav-button {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba(0, 0, 0, 0.5);
                    border: none;
                    padding: 8px;
                    border-radius: 50%;
                    cursor: pointer;
                    z-index: 10;
                
                    &:hover {
                        background: rgba(0, 0, 0, 0.7);
                    }
                
                    &.left {
                        left: 2px !important;
                    }
                
                    &.right {
                        right: 2px !important;
                    }
                }
                
            }

            .ptw {
                .pdh h1 {
                    font-size: 20px;
                    line-height: 26px;
                }

                .prc-ctn{
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .prc-wrp {
                        margin-left: 0; 
                        display: flex;
                        flex-direction: column;
                        align-items: center; 
                }

                .cta-wrapper {
                    .cta-btn {
                        font-size: 12pt;
                        padding: 4px 8px;
                    }
                }
            }
        }

            .pdesc-wrapper {
                .pd-desc {
                    padding: 10px;
                    margin-right: 20px;
                }
            }
        }
    }
}
