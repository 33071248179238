.checkout-wrapper-page{
    position: relative;
    width: 100vw;
    color:$bodyFontColor;

    .inner-checkout{
        width: $inner-width-container-big;        
        margin: auto;
        display: flex;
        flex-direction: column;
        
        .header{
            width: 100%;
            text-align: center;
        }

        .body{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
           
            .method{
                width: calc(100% - 450px);

                .card{
                    border: 1px solid rgb(209, 209, 209);
                    border-radius: $border-radius;    
                    
                    .card-header{
                        background: $header-background;
                        color:white;
                        border-radius: $border-radius $border-radius 0px 0px ;     

                        h3{
                            margin:0;
                            padding:15px 20px;
                        }
                    }

                    .card-body{
                        padding:20px;
                    
                    }
                }
            }

            .summary{
                width:400px;
                
                .card{
                border: 1px solid rgb(209, 209, 209);
                border-radius: $border-radius;            
                    
                    .card-header{
                        background: $header-background;
                        color:white;
                        border-radius: $border-radius $border-radius 0px 0px ;     

                        h3{
                            margin:0;
                            padding:15px 20px;
                        }
                    }

                    .card-body{
                        padding:20px;

                        .item-list{

                            .cart-list-product-wrapper{
                                display: flex;
                                flex-direction: row;
                                align-content: space-between;
                                padding: 10px 0px;
                                border-top: 1px solid #d8d8d8;
                                // cursor: pointer;
                                position: relative;
            
                                // &:hover{
                                //     background: #f5f5f5;
                                // }
            
                                .del-cart-sum{
                                    position: absolute;                        
                                    right: 0;
                                    top: 0;
                                    padding: 5px 5px;
            
                                    &:hover{
                                        color:$color-secondary;
                                    }
                                   
                                }
            
                                .list-product-pict{
                                    height: 100px;
                                    width:100px;
                                }
            
                                .product-list-desc-wrapper{
                                    padding: 10px;
                                    color: $bodyFontColor;
                                    max-width: 265px;
                                    padding-right: 0px;
                                   
                                    
            
                                    .title{
                                        font-weight: 600;
                                        font-size: 11pt;
                                        margin-bottom: 10px;
                                    }
            
                                    .category{
                                        margin-top:5px;
                                        font-size: 8pt;
                                        color:rgb(163, 163, 163)
                                    }
            
                                    .price{
                                        font-weight: 700;
                                        font-size: 12pt;
                                        color: $bodyFontColor;
                                        display: flex;
                                        justify-content: flex-start;

                                    }
                                }
                            }
                        }
                    }

                    .summary-order{
                        table {
                            width:100%;
                            font-weight: 600;
                            line-height: 1.7;
                            border-top: 1px solid rgb(194, 194, 194);
                            border-bottom: 1px solid rgb(194, 194, 194);

                            &:last-child{
                                padding-bottom: 15px;
                                padding-top: 15px;
                                margin-bottom: 15px;
                                margin-top: 15px;
                            }

                            tr{
                                display: flex;
                                justify-content: space-between;
                            }
                        }
                    }

                    .total {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 20px;

                        span{
                            font-size: 10pt;
                        }

                        .number-total{
                            span{
                                font-size: 24pt;
                                font-weight: 700;
                            }
                        }
                    }

                    .codePromoWrapper{

                        .code-promo1{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom:5px;

                            .has{
                                font-size: 10pt;
                                font-weight: 600;
                            }

                            .see{
                                font-size: 9pt;
                            }
                        }

                        .code-promo2{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            
                            .input-promo{
                                height: 35px;
                                outline: none;
                                border-radius: $border-radius 0px 0px $border-radius;
                                border:none;
                                background: rgb(236, 236, 236);
                                padding:10px;
                                font-size: 12pt;
                                font-family:'Poppins', sans-serif;
                                text-transform: uppercase;
                                color:rgb(143, 143, 143);
                                width: calc(100% - 20px);
                            }

                            .button-kode-promo{
                                background: $color-secondary;
                                border-radius: 0px $border-radius $border-radius 0px;
                                padding: 10px;
                                text-transform: uppercase;
                                color:white;
                                border:none;
                                font-weight: 600;
                                cursor: pointer;

                                &:focus{
                                    outline: none;
                                }

                                &:hover{
                                    background: $color-secondary-light;
                                }
                                
                            }

                            
                        }

                    }
                    
                    .pay-now{
                        margin-top: 20px;
                        .bayar-sekarang{
                            padding: 15px 30px;
                            cursor: pointer;
                            -family: $body-font;
                            outline: none;
                            border-radius: $border-radius;
                            border-right: none;
                            border-left: none;
                            border-top:none;      
                            width: 100%;
                            text-align: center;
                            color:rgb(255, 255, 255);
                            font-size: 22pt;
                            background: $attention-color;
                            border-bottom: 10px solid $attention-color-dark;              
        
                            
                            &:hover{
                                background: $attention-color-light;
                                border-bottom: 10px solid $attention-color;              
                            }
        
                            &:active{
                                border-bottom: 0px solid $attention-color;         
                                transform: translateY(4px);   
                                margin-top:10px;                        
                            }   
                            
                            &:disabled{
                                border-bottom: 0px solid grey;   
                                background: rgb(173, 173, 173);      
                                transform: translateY(4px);   
                                margin-top:10px;                        
                            }    
                        }
                    }
                }
            }
        }

        
        
    }
}

.add-alamat-btn{                        
    padding: 15px 15px;
    cursor: pointer;
    font-family: $body-font;
    outline: none;
    font-weight: 600;
    border-radius: $border-radius;
    border-right: none;
    border-left: none;
    border-top: none;
    width: 40%;
    text-align: center;
    color: white;
    font-size: 11pt;
    background: $color-secondary;

    
    &:hover{
        background: $color-secondary-light;
    }
   
}

.shipped-to-wrapper{
    display: flex;
    flex-direction: column;
    line-height: 1.7;
    margin:15px 0px;

    .to{
        font-size: 18pt;
        font-weight: 700;
        margin-bottom: 10px;
        span{
            font-size: 10pt;
            color:rgb(160, 160, 160)
        }
    }

    .phone{
        
    }
}

.shipped-button-action{
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    border-top: 1px solid rgb(214, 214, 214);
    justify-content: flex-end;

    button{
        background: white;
        border: 1px solid rgb(190, 190, 190);
        color:grey;
        cursor: pointer;
        padding: 10px;
        border-radius: $border-radius;
        margin-right: 10px;
        font-size: 10pt;
        
        &:hover{
            background: rgb(231, 231, 231);
        }
    }
}

.ss-wrapper{
    padding: 10px;

    .body-sship{
        display: flex;
        flex-direction: column;

        .courier-wrapper{
            padding: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            cursor: pointer;

            &:hover{
                background: rgb(241, 241, 241);
                border-radius: $border-radius;
            }
            
            .checkradio-courier{
                width: 25px;
                height: 25px;
                margin-right: 20px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                .checkradio-courier-circle{
                    
                    width: 25px;
                    height: 25px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;                    
                    border:1px solid rgb(158, 158, 158);
                    background: white;
                    

                    .checkradio-inner-circle{
                        width: 10px;
                        height: 10px;
                        background: white;
                        border-radius: 100%;
                    }
                }  
                
                .courier-checked{
                    background:$color-secondary !important;
                    border:none;
                }
            }

            .courier-desc{
                flex-grow: 2;
                h3 {
                    margin:0;
                    text-transform: uppercase;
                
                }
                span{
                    font-size: 10pt;
                }
            }

            .courier-price{
                span{
                    font-size: 16pt;
                    font-weight: 600;
                }
            }
        }
    }
}

@media screen and (max-width:$break-medium){
    .checkout-wrapper-page{
        .inner-checkout{
            width: $inner-width-container-medium;   
            .body{
                flex-direction: column;
                .method{
                    width: 100%;

                    .card{
                        margin-top:15px;
                    }
                }

                .summary{
                    width: 100%;

                    .card{
                        margin-top:15px;
                    }
                }
            }
        }
    }    
}

@media screen and (max-width:910px){

    .checkout-wrapper-page{

        .inner-checkout{
            
            .body{
                flex-direction: column;

                .method{
                    width: 100%;

                    .card{
                        margin-top:15px;
                    }
                }

                .summary{
                    width: 100%;

                    .card{
                        margin-top:15px;
                    }
                }
            }

        }
    }
    
}

@media screen and (max-width:1028px){

    .checkout-wrapper-page{

        .inner-checkout{
            width: 90%;   

        }
    }
    
}

