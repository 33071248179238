.Imprint-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;


    .Top-imprint {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      margin-left: 100px;

      .title-imprint {
        display: flex;
        align-items: center;
        gap: 5px;

        img {
          height: auto;
          width: 250px;
          object-fit: cover;
        }

        .text-container {
          display: flex;
          flex-direction: column;
          gap: 8px;

          h1 {
            font-size: 30px;
            margin: 0;
            color: #ffffff;
            white-space: nowrap;
          }

          p {
            color: rgb(255, 255, 255);
            margin: 0;
          }
        }
      }
    }

    .banner-imprint {
      display: flex;
      position: relative;
      width: $inner-width-container-big;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 8px;
      
    }

    .banner-image {
      width: 100%;
      max-width: 66.8%;
      height: auto;
      object-fit: contain;
      position: relative;
      border-radius: 10px;
        
    }

    .prev-btn, .next-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      cursor: pointer;
      padding: 10px;
      font-size: 20px;
      z-index: 2;
      
    }

    .prev-btn {
      left: calc(50% - (66.8% / 2) + 10px);
    }

    .next-btn {
      right: calc(50% - (66.8% / 2) + 10px);
    }

    .prev-btn:hover, .next-btn:hover {
      background-color: rgba(204, 204, 204, 0.8);
    }

    .slider-imprint {
      position: relative;
      margin-top: 50px;
      background: #e2e2e2;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      max-width: 63%;
      width: 100%;
      height: auto;
      align-items: center;
      margin-left: auto;
      margin-right: auto; 
      display: flex;
      flex-direction: column; 
      padding: 0;
      
      

      .title-slider-imprint {
          text-align: center;
  
          h1 {
              font-size: 20px;
              font-weight: bold;
              color: #000000;
              border-bottom: 3px solid $color-secondary;
              padding-bottom: 5px;       
          }
          h2{
            
          }
      }
      
      .book-slider-imprint {
          display: flex;
          gap: 10px;
          overflow-x: auto;
          scroll-behavior: smooth;
          padding-bottom: 10px;
          justify-content: flex-start;
          
          
          &::-webkit-scrollbar {
              display: none; 
          }
  
          .book-item {
            display: flex;
            position: relative;
            width: $inner-width-container-small;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            border-radius: 8px;
  
              &:hover {
                  transform: scale(1.05);
              }
  
              img {
                  width: 100%;
                  max-height: 250px;
                  object-fit: cover;
                  border-radius: 5px;
              }
  
              p {
                  font-size: 16px;
                  font-weight: 500;
                  text-align: center;
              }
          }
      }
  }
  

    .product-search-wrapper{

      .product-search-inner{
        display: flex;
        color: #ffffff;
        

      }

    }
 
    // .book-section {
    //   padding: 32px;
    //   justify-content: center;
    //   margin-left: auto;
    //   margin-right: auto;
    //   margin-top: 24px;
    //   width: 100%;

    //   h2 {
    //     margin-bottom: 24px;
    //     margin-top: 0;
    //   }

    //   .filter-tags {
    //     display: flex;
    //     align-items: center;
    //     margin-bottom: 15px;

    //     .tag {
    //       background: #007bff;
    //       color: white;
    //       padding: 5px 10px;
    //       border-radius: 5px;
    //       margin-right: 10px;
    //     }

    //     button {
    //       border: none;
    //       background: none;
    //       color: red;
    //       cursor: pointer;

    //       &:hover {
    //         text-decoration: underline;
    //       }
    //     }
    //   }
    // }
  }


@media (max-width:$break-medium) {
  .Top-imprint {
    display: flex;
    flex-direction: row; 
    align-items: center; 
    justify-content: flex-start; 
    margin-left: 0 !important; 
    width: 100%;

    .title-imprint {
      display: flex;
      align-items: center;
      gap: 4px; 

      img {
        height: 100px !important; 
        width: auto;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 4px;

        h1 {
          font-size: 20px; 
          white-space: nowrap;
          text-align: left;
        }

        p {
          font-size: 15px;
          margin: 0;
        }
      }
    }
  }
  .banner-image {
    height: 180px; 
  }
  .slider-imprint{
    

}
}
@media (max-width:$break-small) {
  .Top-imprint {
    display: flex;
    flex-direction: row; 
    align-items: center; 
    justify-content: flex-start; 
    margin-left: 0 !important; 
    width: 100vw !important;

    .title-imprint {
      display: flex;
      align-items: center;
      gap: 0px !important; 

      img {
        height: auto ; 
        width: 150px !important;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-right: 30px;

        h1 {
          font-size: 15px !important;
          white-space: nowrap;
          text-align: left;
        }

        p {
          font-size: 10px;
          margin: 0;
        }
      }
    }
  }

  .banner-imprint {
    position: relative;
    width: 100vw;
    flex-direction: column;
  }

  .banner-image {
    max-width: 100vw !important;
    padding: 0;

  }

  .prev-btn, .next-btn {
    bottom: 10px !important;
    
  }
  .prev-btn {
    z-index: 40 !important;
    left: 15px !important;
    
  }

  .next-btn {
    z-index: 40 !important;
    right: 15px !important;
  }

  .slider-imprint{
        max-width: 90%;
        margin-top: 30px;
        border-radius: 5px;
        padding: 10px;

  }

  .book-section {
    width: 40%;
    padding: 10px;
    margin-top: 10px;

  
  }
}

