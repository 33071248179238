.tracking-ui-wrapper{
    width: 100%;
    
  

    .tracking-header{
        width: 100%;
        height: 100px;
        overflow: hidden;
        background: rgba(94, 212, 248, 0.185);
        border-radius: $border-radius;
        position: relative;
        display: flex;
        align-content: center;
        align-items: center;

        img{
            position: absolute;
        }

        .tracking-basic-info{
            width: 100%;
            text-align: right;
            padding: 20px;

            h3{
                margin:0;
            }
        }
    }

    .tracking-body{
        width: 80%;
        margin: auto;
        margin-top:30px;
    }
}