* {
    box-sizing: border-box;
}

.wrapper{
    position: relative;
    top:0px;
    height: 100vh;
    width: 100%;
    font-family: $body-font;

    &.wrapper-full-page{
        min-height: 100vh;
        height: auto;
    }

    .main-page{        
        position: relative;
        // background: pink;
        background-image : url('https://res.cloudinary.com/dhitaaaa/image/upload/v1741812948/background-ipbpress_ecdjr4.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        width: 100vw;
        margin-top: 65px;
        margin-bottom: 65px;
        // min-height: 100vh;
        height: auto;
    }    
}
.Imprint-page{        
    position: relative;
    background-image : url('https://res.cloudinary.com/dhitaaaa/image/upload/v1742434418/patrick-tomasso-Oaqk7qqNh_c-unsplash_gwmvji.jpg') !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100vw;
    margin-top: 65px;
    margin-bottom: 65px;
    // min-height: 100vh;
    height: auto;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); 
       
        z-index: 1;
    }
    > * {
        position: relative;
        z-index: 2;
    }
}  
 
