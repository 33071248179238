.order-detail-wrapper{
    position: relative;
    width: 100vw;

    .inner-order-detail-wrapper{
        width: $inner-width-container-big;        
        margin: auto;
        display: flex;        
        padding-top:20px;
        flex-direction: row;
        justify-content: space-between;

        .product-list-wrapper{

            .cart-list-product-wrapper{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px 0px;
                border-top: 1px solid #e6e6e6;               
                // cursor: pointer;
                position: relative;

                // &:hover{
                //     background: #f5f5f5;
                // }

                .del-cart-sum{
                    position: absolute;                        
                    right: 0;
                    top: 0;
                    padding: 5px 5px;

                    &:hover{
                        color:$color-secondary;
                    }
                   
                }

                .list-product-pict{
                    height: 100px;
                    width:100px;
                }

                .product-list-desc-wrapper{
                    flex:2;
                    padding: 10px;
                    color: $bodyFontColor;
                    max-width: 265px;
                    padding-right: 0px;
                    cursor: pointer;
                                   
                    .title{
                        font-weight: 600;
                        font-size: 12pt;
                    }

                    .category{
                        margin-top:5px;
                        font-size: 8pt;
                        color:rgb(163, 163, 163)
                    }

                }
                        .price{
                            // align-items: center;
                            padding: 10px;
                            font-weight: 700;
                            font-size: 12pt;
                            color: $bodyFontColor;
                            display: flex;
                            justify-content: flex-end;
    
                        }
            }
        }

        .summary-order-detail{
            padding-top: 20px;
            border-top:1px solid #e6e6e6;

            .number-order-list{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .number-order-list-total{
                margin-top:10px;
                padding-top: 10px;
                border-top:1px solid #e6e6e6;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: bold;
                font-size: 16pt;
            }
        }

        .right-sidebar-order{
            width: 35%;
            border: 1px solid rgb(230, 230, 230);
            border-radius: $border-radius;
            padding: 20px;
            

            .short-desc-order-wrapper{
                justify-content: flex-start;    
                align-items: center;
                padding: 10px 0px;
                border-bottom: 1px solid #e6e6e6;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

                label{
                    font-size: 11pt;
                    font-weight: 500;
                    margin-bottom: 5px;
                    color: $color-secondary;
                }

                h4{
                    color:rgb(119, 119, 119);
                }

                p{                    
                    font-size: 10pt;
                    color:rgb(119, 119, 119);
                }

                .clock-order{
                    padding: 10px;
                }
            }

            
            
        }

        .main-order-detail{
            width: 63%;
            border: 1px solid rgb(230, 230, 230);
            border-radius: $border-radius;
            padding: 20px;
            min-height: 100vh;

            .tracking-status-wrapper{
                padding: 35px 25px;
                background: #fcfcfc;
                margin-bottom: 20px;
            }

        }

    }
}

.flex-row{
    display: flex;
    flex-direction: row;
}

.flex-column{
    display: flex;
    flex-direction: column
}

.flex-space-between{
    justify-content: space-between;
}

@media screen and (max-width:$break-medium){
    .order-detail-wrapper{
    
        .inner-order-detail-wrapper{
            
    
            .main-order-detail{
               
                .tracking-status-wrapper{
                    padding: 10px;
                    overflow: scroll;
                }
                
            }                               
        }
    }
    
    
    }


@media screen and (max-width:$break-medium){
.order-detail-wrapper{

    .inner-order-detail-wrapper{
        width: $inner-width-container-small;    
        flex-direction: column;

        .main-order-detail{
            width: 100%;
            min-height:50vh;
            margin-bottom: 20px;

            .tracking-status-wrapper{
                padding: 10px;
            }
            
        }
        
        .right-sidebar-order{
            width: 100%;
        }

        .product-list-wrapper{

            .cart-list-product-wrapper{
                flex-direction: column;
                align-items: center;
            }
        }


    }
}


}
