.detail-imprint {
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

//Book Container (Atas) 
.book-container {
  max-width: 900px;
  width: 100%;
  display: flex;
  justify-content: space-between; 
  gap: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

// Thumbnail 
.book-display {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.book-thumbnails {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.book-thumbnails img {
  width: 50px; 
  height: 70px;
  object-fit: cover;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.book-thumbnails img.active {
  border: 2px solid #ff5722; 
}

.book-image img {
  width: 280px; 
  height: auto;
  margin-bottom: 10px;
}

//Info 
.book-info {
  flex: 1.5; 
  margin-left: 50px;
}

.title-book {
  font-size: 28px; 
  font-weight: bold;
  margin-bottom: 10px;
}

.author {
  color: #757575;
  font-size: 14px; 
  margin-bottom: 20px;
}

.price-section {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 38px;
}

.discounted-price {
  font-size: 24px;
  font-weight: bold;
  color: #d32f2f;

}

.original-price {
  font-size: 14px;
  text-decoration: line-through;
  color: #999;
}

.discount {
  font-size: 12px;
  font-weight: bold;
  color: #d32f2f;
  background: #c3f1f1;
  padding: 3px 6px;
  border-radius: 4px;
}

//jumlah beli
.quantity-selector {
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-controls {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.quantity-controls button {
  background: #f5f5f5;
  border: none;
  padding: 6px 10px;
  font-size: 16px;
  cursor: pointer;
}

.quantity-controls .quantity {
  padding: 6px 12px;
  font-size: 16px;
  font-weight: bold;
  color: #229799;
}

//tombol 
.button-group {
  display: flex;
  gap: 10px;
  margin-top: 60px;
}

.cart-button,
.buy-button {
  background-color: #229799;
  color: white;
  border: 2px solid #229799;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.cart-button:hover,
.buy-button:hover {
  background-color: #1a7b7f;
  border-color: #1a7b7f;
}

.cart-button {
  background-color: white;
  color: #229799;
}

.cart-button:hover {
  background-color: #229799;
  color: white;
}

//detail buku
.book-details {
  max-width: 900px;
  width: 100%;
  display: flex;
  margin-top: 40px;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  gap: 5px;
}

.details-container {
  display: flex;
  justify-content: space-between; 
}


.specifications{
  flex: 1;
  margin-left: 10px;
  
}
.description {
  flex: 2;
  margin: 0;
}


@media (max-width: 768px) {
  .book-container {
    flex-direction: column; 
    align-items: center; 
    padding: 15px;
  }

  .book-image img {
    width: 220px; 
    margin-top: 10px;
  }

  .book-info {
    text-align: justify; 
  }

  .title-book {
    font-size: 25px; 
    margin-top: 5px;
  }
  .price-section{
    margin: 10px 0;
  }
  .discounted-price {
    font-size: 20px;  
  }

  .quantity-selector {
    margin-top: 20px;
   
  }

  .button-group {
    align-items: center;
    margin-bottom: 20px;
    margin-top: 40px;
    margin-right: 22px;
    
  }

  .book-details {
    flex-direction: column;
  }

  .details-container {
    flex-direction: column;
    gap: 10px;
    
    .specifications{
      margin-left: 40px;

    }
    .description{
      margin-left: 40px;
    }
  }
  
}
