.slider-wrapper{
    position: relative;    
    padding-top:30px;
    width: 100vw;
    // height: 285px;
    

    .inner-container-slider{
        width: $inner-width-container-big;        
        margin: auto;
        display: flex;
        flex-direction: row;
        // height: 100%;
        // background: pink;

        .main-slider{            
            width: 100%;
            position: relative;            
            max-width: 66.8%;  
            cursor: pointer;    
                 
            .item-slider-wrapper:focus{
                outline: none;
                
            }
        }

        .promo-static-slider{
            width: 100%;
            position: relative;
            max-width: 31.9%;
            display: flex;
            flex-direction: column;
            margin-left:13px;
            // overflow: hidden;
            
            .static-slider-item{                
                // width: 326px;               
                &:first-of-type{
                    margin-bottom:15px; 
                }
            }           
        }

    }

    .loading-slider{
        // margin: 10px;
        // max-width: 380px;
        // height: 141px;    

        // background: url('https://ipbstore.com/wp-content/uploads/2019/01/HP-FE-3-1.png');
        // background-repeat: no-repeat;
        // background-size: cover; 
        // background-position: center;

        // height: 255px;
        // responsive 
        &:after{
            content: "";
            display: block;
            padding-bottom: 37%;
        }

        // border-radius: $border-radius;

        &.static-slider{
            // height: 121px;           
        }
        background:linear-gradient(-45deg, rgb(187, 184, 184), rgb(219, 219, 219));
        background-size: 300% 300%;
        // -webkit-animation: Gradient 0.5s ease infinite;
        // -moz-animation: Gradient 0.5s ease infinite;
        // animation: Gradient 0.5s ease infinite;
        
    }
    
}

@media screen and (max-width:$break-small) {
    .slider-wrapper{
        padding-top: 0px;
        // height: 350px !important;
        position: relative;


        .inner-container-slider{
            width: 100%;
            flex-direction: column;
            // height: 100%;

            .main-slider{
                max-width: 100vw;
                // height: 100%;
                padding: 0px;
            }

            .promo-static-slider{
                flex-direction: row;                
                // background:#000058;
                // background: white;
                width: 100%;
                position: relative;
                max-width: 100%;
                display: flex;                           
                margin: 0;
                // height: 100%;

                .static-slider-item{                
                    width: 100%;  
                    margin: 8px;             
                    // &:first-of-type{
                    //     margin-bottom:13px; 
                    // }
                }           
            }
        }


        .loading-slider{                           
            // height: 140px !important;

            &.static-slider{
                // height: 90px !important;
            }            
        }
    }
}

@media screen and (max-width:$break-medium){

    .slider-wrapper{
        padding-top: 0px;
        // height: 66vh;
        position: relative;


        .inner-container-slider{
            width: 100%;
            flex-direction: column;
            // height: 100%;

            .main-slider{
                max-width: 100vw;
                // height: 100%;
                // padding: 8px;
            }

            .promo-static-slider{
                flex-direction: row;                
                // background:#000058;
                // background: white;
                width: 100%;
                position: relative;
                max-width: 100%;
                display: flex;                           
                margin: 0;
                // height: 100%;

                .static-slider-item{                
                    width: 100%;  
                    margin: 8px; 
                    margin-top: 12px !important;                                
                }           
            }
        }

        .loading-slider{                           
            // height: 42vh;
            &.static-slider{
                // height: 20vh;
            }           
        }
    }
}



@-webkit-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

.slick-dots{
    bottom: 10px !important;
}


    .slick-next {
        z-index: 40 !important;
        right: 15px !important;

        &:before{
            color: $bodyFontColor !important;
            font-size: 30px !important;
        }
    }
    .slick-prev{
        z-index: 40 !important;
        left: 15px !important;

        &:before{
            color: $bodyFontColor !important;
            font-size: 30px !important;
        }
        
    }
