.popup-wrapper{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top:0;
    z-index: 1106;
    display: flex;
    align-items: center;
    justify-content: center;    
    

    .popup-card{
        min-width: 400px;
        min-height: 130px;
        background: white;
        padding:20px;
        z-index: 100;
        border-radius: 5px;
        max-height: 100vh;
        overflow: scroll;

        .popup-header{
            border-bottom:1px solid rgb(207, 207, 207);
            padding: 10px 0px;
            display: flex;
            align-content: center;
            justify-content: center;
            font-weight: 700;
            font-size: 15pt;
            color:grey;
            position: relative;

            i{
                position: absolute;
                top:10px;
                right: 10px;
                cursor: pointer;
            }
        }

        .popup-body{
            padding: 20px 0px;
            display: flex;
            overflow: hidden;


            .displayProduct{
                width: 20%;                
                background: gray;
                display: block;                

                &:after{
                    content:"";
                    display: block;
                    padding-bottom: 100%;
                }
            }

            .ProductTitle{
                padding: 0px 15px;
                color: rgb(155, 155, 155);
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }
}

@media screen and (max-width:460px){
    .popup-wrapper{
        .popup-card{
            min-width: 100px;
            width: 90%;
        }
    }
}

.hide{
    display: none !important;
}

.popup_displayproduct {
    width: 100%;
    display: flex;
    flex-direction: row;
}