.product-slider-wrapper{
    position: relative;
    width: 100%;
    margin-top:50px;
    margin-bottom:30px;
    height: 380px;
    // background: yellow;

    .inner-product-slider{
        width: $inner-width-container-big; 
        margin: auto;
        height: 100%;
        // background: grey;
        display: flex;  
        flex-direction: column;  
        
       

        .product-slider-section-wrapper{
            display: flex;
            flex-direction: row;
            height: 100%;
            padding-left: 0px;

            .product-slider-section-title{
                width: 17%;
                height: 100%;
                background: rgb(70, 70, 70);
                margin-right: 5px;
                cursor: pointer;
                border-radius: $border-radius;
                margin-bottom: 20px;
               
            }
            
            .product-slick-carousel{
                width: 100%;
                margin: auto;


                .title-section-product-slider{
                    display: flex;
                    width: 100%;
                    margin-bottom: 16px;
                    margin-top: 16px;
                    flex-direction: row;
                    align-items: center;
                    text-align: center;
                    justify-content: center;                    
                    border-bottom: 3px solid whitesmoke; 
                    height: 42px;
                    padding: 10px 20px;
                    position: relative;
                    padding-bottom: 3px;
                    padding-top: 0;
                   
                   

                    
        
                    .title-section-slider{
                        font-family: 'Poppins', sans-serif;
                        color: $bodyFontColor;
                        font-weight: 600;
                        font-size: 20px;
                        padding-bottom: 15px;
                        border-bottom: 3px solid $color-secondary;
                        text-align: center;
                        justify-content: space-between;
                        
                       

                    }
                    .title-section-product-slider::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 3px;
                        background-color: whitesmoke;
                       
                    }
        
                    .look-all-product-slider{
                        color:$color-secondary;
                        font-size: 12px;
                        cursor: pointer;
                        text-align: right;
                        margin: 0;
                    }
                }
    
                .product-slick-carousel-inner{
                    // width: 1800px;

                    .product-slide-item{
                        margin-right:5px;
                        margin-bottom:5px;
                    }
                    .slick-next {
                        position: absolute;
                        z-index: 40 !important;
                        right: 15px !important;
                        top: 35% !important;
                        right: -10px !important;
                
                        &:before{
                            color: $bodyFontColor !important;
                            font-size: 30px !important;
                        }
                    }
                    .slick-prev{
                        position: absolute;
                        z-index: 40 !important;
                        left: 15px !important;
                        top: 35% !important;
                        left: -20px !important;
                
                        &:before{
                            color: $bodyFontColor !important;
                            font-size: 30px !important;
                        }
                        
                    }
                    
                    div{
                        outline: none;                                                
                    }
                }
            }
        }        
    }
}

.product-card-slider-loading-wrapper{    
    height: 290px;
    overflow: hidden;
}

@media screen and (max-width:$break-small) {
    .product-slider-wrapper{
        margin-top: 10px;
        display: flex;
        overflow-x: auto !important; 
        scroll-behavior: smooth;
        white-space: nowrap; 
        width: 98% !important;

        .inner-product-slider{
            width: 100% !important;
            

            // .title-secion-product-slider{

            // }
            
            .product-slider-section-wrapper{
               
                
                .product-slider-section-title{
                    display: none;
                }

                .product-slick-carousel{
                    width: 100%;
                    

                    .title-section-product-slider{
                        width: 100%;
                        box-sizing: border-box;
                        padding: 10px 20px;
                        border-bottom: 3px solid #f0eee9; 
                        padding-bottom: 17px;
                        margin-top: 10px;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: nowrap;
                       
                       

                    }
                    .title-section-slider {
                        font-family: 'Poppins', sans-serif;
                        color: $bodyFontColor;
                        font-weight: 600;
                        font-size: 20px;
                        padding-bottom: 15px;
                        border-bottom: 3px solid $color-secondary;
                        text-align: center;
                        justify-content: space-between;
                        margin-right: 37px !important;
                        
                    }

                    .look-all-product-slider {
                        color:$color-secondary;
                        font-size: 12px;
                        cursor: pointer;
                        text-align: right;
                        margin: 0;
                    }
                    
                    .product-slick-carousel-inner{
                        // width: 1800px;
    
                        .product-slide-item{
                            margin-right:5px;
                            margin-bottom:5px;
                        }
                        .slick-next {
                            z-index: 40 !important;
                            right: 15px !important;
                            top: 35% !important;
                            right: 12px !important;
                    
                            &:before{
                                color: $bodyFontColor !important;
                                font-size: 30px !important;
                            }
                        }
                        .slick-prev{
                            z-index: 40 !important;
                            left: 15px !important;
                            top: 35% !important;
                            left: 2px !important;
                    
                            &:before{
                                color: $bodyFontColor !important;
                                font-size: 30px !important;
                            }
                            
                        }
                        
                       
                    }

                    
                    
                }

                
            }
        }
        
    }
}

@media screen and (max-width:$break-medium) {
    .product-slider-wrapper{

        .inner-product-slider{
            width: $inner-width-container-medium;

            .product-slider-section-wrapper{
                
                .product-slider-section-title{
                    display: none;
                }
                
                .product-slick-carousel{
                    .title-section-product-slider{
                        width: 100%;
                        box-sizing: border-box;
                        padding: 10px 20px;
                        border-bottom: 3px solid #f0eee9; 
                        padding-bottom: 17px;
                        margin-top: 10px;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: nowrap;
                        margin-right: 8px !important;
                        
        
                    }
                    .title-section-slider {
                        font-size: 24px; 
                        max-width: 100%; 
                        white-space: normal; 
                        overflow: visible;
                        text-overflow: unset;
                        line-height: 1.2;
                        border-bottom: 3px solid $color-secondary;
                        padding-top: 10px;
                        
                    }
                    
                    
                }
            }
        }
    }
}


.loading-background {
    background:linear-gradient(-45deg, #e9e9e9, rgb(219, 219, 219)) !important;
    background-size: 300% 300% !important;
	-webkit-animation: Gradient 0.5s ease infinite !important;
	-moz-animation: Gradient 0.5s ease infinite !important;
	animation: Gradient 0.5s ease infinite !important;
}







