.pyrocessing-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: white;
  z-index: 101;
  margin-top: -65px;

  h1 {
    margin-bottom: 15px;
  }

  .bpw-payment {
    position: relative;
    width: 60%;

    .background-checkout {
      position: relative;
        // display: flex;
        // justify-content: center;

      .logo-checkout {
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;

        img {
          height: 40vmin;
        }
      }
    }
  }
}


@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 20px); }
    to   { transform: translate(0, -0px); }    
}

.floating-animation {  
    animation-name: floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
   
}