.store {
    justify-content: center !important;
  
}

.store h2 {
    font-size: 24px;
    font-weight: bold;
    color: #4d4d4d;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 0px;
    
}

.store-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    gap: 20px;
    padding-bottom: 16px;
    scrollbar-width: none; 
    -ms-overflow-style: none;
}

.store-container::-webkit-scrollbar {
    display: none;
}

.store-box {
    flex: 0 1 200px; 
    max-width: 100%;
    width: 200px;
    height: 180px; 
    background: #ffffff;
    padding: 15px;
    text-decoration: none;
    color: #101d60;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.store-box:hover {
    transform: scale(1.1);
}

.store-image {
    max-width: 100px;
    width: 100%;
    height: auto; 
    margin-bottom: 10px;
}
.icon-imprint{
    margin-bottom: 25px !important;
    margin-top: 30px;
    width: 100% !important; 
}
.icon-other{
    margin-bottom: 0px !important;
    margin-top: 15px;
    width: 45%;

}


.store-box h3 {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.store-imprint p{
    margin-top: 0;
    
}

@media (max-width: $break-medium) {
    .store-container {
        display: flex;
        justify-content: center; 
        align-items: center;
        gap: 10px;
        flex-wrap: nowrap; 
        max-width: 100%;
        width: 100%; 
        overflow-x: auto; 
        white-space: nowrap;
        padding: 10px 0;
        margin-left: 0px;
        
    }

    

    .store-box {
        min-width: 120px;
        height: 140px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background: white;
        border-radius: 8px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        flex-shrink: 0; 
    }

    .store-image {
        width: 60px;
        height: auto;
    }
    .icon-imprint{
        margin-bottom: 10px !important;
        margin-top: 30px;
        width: 100% !important; 
    
    }
    .icon-other{
        margin-bottom: 0px !important;
        margin-top: 15px;
    
    }

    .store-box h3 {
        font-size: 12px;
        text-align: center;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .store-imprint p{
        margin-top: 14px;
        
    }
}

@media (max-width: $break-small) {
    .store{
        justify-content: center;
        margin-left: 10px;
        padding: 2px 5px;
    }
    .store-container {
        display: flex;
        justify-content: center; 
        align-items: center;
        gap: 10px;
        flex-wrap: nowrap; 
        width: 100%; 
    }

    .store-box {
        width: 10px; 
        min-width: 110px;
        max-width: max-content;
        height: 110px; 
        padding: 6px;
        background: white;
        border-radius: 8px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        flex-shrink: 1; 
        flex-grow: 0;
        text-align: center;
        justify-content: center;
    }

    .store-image {
        width: 47px; 
        height: auto;
        margin-bottom: 0px;
    }
    .icon-imprint{
        margin-bottom: 6px !important;
        margin-top: 30px;
        width: 90% !important; 
    
    }
    .icon-other{
        margin-bottom: 0px !important;
        margin-top: 20px;
        width: 50%;
    
    }
    .store-box h3 {
        font-size: 10px;
        font-weight: 100;
        margin-top: 0px;
        padding: 0 5px;
        line-height: 1.1;
        white-space: nowrap;
        overflow: visible;
        text-overflow: ellipsis;
        
    }
    .store-imprint p{
        margin-top: 15px;
        
    }
    
}
