.search-address-trigger{
   color:black;
   cursor: pointer;
   
}

.input-search-alamat{
    border-radius: $border-radius $border-radius 0px 0px !important;
}

.search-alamat-result{
    position: absolute;
    background: white;
    top:100%;
    width: 100%;
    border-radius: 0px 0px  $border-radius $border-radius;
    border-right: 1px solid rgb(219, 219, 219);
    border-bottom: 1px solid rgb(219, 219, 219);
    border-left: 1px solid rgb(219, 219, 219);
    z-index: 100;


    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        
        li{
            padding: 10px;
            cursor: pointer;
            font-size: 10pt;
            color: $bodyFontColor;           

            &:hover{
                background:#5897fb;
                color: white;
            }

            .highlighted{
                background:#5897fb;
                color: white;
            }
        }
    }
}



.form-control{
    width: 100%;
    height: 45px;
    border-radius: $border-radius;
    padding: 10px;
    margin: 8px 0px;
    border: 1px solid rgb(219, 219, 219);
    font-size: 12pt;
    outline: none;
}

.address-listwrapper{
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    color: #343030;
    margin-top:20px;
    max-height: 70vh;
    overflow: auto;

    li{
        margin-bottom: 10px;
        border: 1px solid #80808052;
        padding-bottom: 10px;
        padding: 10px;
        border-radius: $border-radius;
        position: relative;

        .name{
            font-weight: 600;
        }

        .phone{
            font-size: 12pt;
            margin-bottom: 10px;           
        }

        .address{
            font-size: 10pt;
        }

        .button{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 0;
            height: 100%;
            top:0;
            padding: 10px;

            .choose-address{
                background: $header-background;
                color: white;
                padding: 10px 20px;
                font-size: 10pt;
                border-radius: $border-radius;
                border:none;
                cursor: pointer;
                outline: none;
            }
        }
    }
}