.about-us {
    margin: 0;
    padding: 0;


    .main {
        width: 100%;
        height: 100%;
        position: relative;
        margin-bottom:100px;
        display: flex;

        video {
            display: flex;
            width: 100%;
            height: auto;
            object-fit: cover;
            max-width: 100%;

    }
        //teks
        .content {
            display: flex;
            position: relative;
            width: 100%;
            height: 100%;
            top: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: rgb(255, 255, 255);
            font-size: 30px;
            padding: 10px;
        }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Video lebih gelap */

        }
    
        }
                       
    .background{
            max-width: 1000px; 
            width: 100%;
            margin: 20px auto;
            background-color: white;
            padding: 20px;
            border-radius: 15px;
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2) ;
            display: flex;

            .description {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: justify;
                max-width: 1200px;
                margin: auto;
                padding: 40px 40px; 
                gap: 20px;
                position: relative;
                // .subheading {
                //     color: rgb(18, 16, 90);
                //     font-weight: bold;
                //     font-size: 14px;
                //     letter-spacing: 1px;
                //     margin-bottom: 5px;
                //     text-align: center;
                //     width: 100%;
                // }
                    
                    //teks
                .descdetail {
                    width: 50%;
                    max-width: 1000px; 
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    color: rgb(24, 15, 99);
                    position: relative;
                      
        
                        h2 {
                            font-size: 35px;
                            font-weight: bold;
                            margin-bottom: 15px;
                            position: relative;
                            white-space: nowrap;
                            display: inline-block;
                            
                            &::after {
                                content: "";
                                width: 100%;
                                height: 4px;
                                background: #ffcc00;
                                position: absolute;
                                bottom: -5px;
                                left: 0;
                            }
                        }
                            p {
                                font-size: 15px;
                                line-height: 1.6;
                                margin-top: 10px;
                            }       
                    }
                    
                    //video
                    .video-box {
                        width: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                    
                            iframe {
                                width: 100%;
                                max-width: 600px;
                                height: 320px;
                                border-radius: 15px;
                                box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
                            }
                        }
                    }
        }
    
        .services {
            max-width: 1000px; 
            margin: 20px auto;
            text-align: center;
            padding: 30px 20px;
            background-color: rgb(255, 255, 255);
            color: rgb(11, 20, 89);
            border-radius: 15px;
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2) ;
            
            

            h2 {
              font-size: 2rem;
              margin-bottom: 30px;
            }
          
            .service-container {
              display: grid;
              justify-content: center;
              flex-wrap: wrap;
              gap: 16px;
              grid-template-columns: repeat(3, 1fr);
              margin-left: 70px;
             
            }
          
            .service-box {
              background: transparent;
              color: rgb(7, 25, 105);
              text-decoration: none;
              text-align: center;
              padding: 20px;
              transition: transform 0.3s ease;
              max-width: 200px;
              flex: 1 1 200px;
               
          
              &:hover {
                transform: scale(1.1);
              }
          
              .service-image {
                width: 80px;
                height: 80px;
              }
              h3 {
                font-size: 1.2rem;
                margin-bottom: 10px;
                margin-top: 0;
                margin-bottom: 10px;
              }
          
              p {
                font-size: 1rem;
              }
            }
      }
      
      .more-info {
        max-width: 1000px;
        margin: auto;
        padding: 30px 20px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
        color: #0b1459;
        display: flex;
        align-items: center;
        gap: 20px;

        .info-image{
          width: 250px;
          height: auto;
          border-radius: 10px;
          object-fit: cover;
        }

          .info-text{
            flex: 1;

            h2 {
              font-size: 2rem;
              font-weight: bold;
              margin-bottom: 15px;
              margin-top: 0;
              margin-bottom: 10px;
            }
          
            p {
              font-size: 1.1rem;
              line-height: 1.6;
              margin-bottom: 20px;
            }
          
            ul {
              list-style: none;
              padding-left: 0;
          
              li {
                font-size: 1rem;
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 10px;
                font-weight: 500;
              }
          
              li::before {
                content: "✅";
                font-size: 1.2rem;
              }

          }
       
        }
        
      }
      .visi-misi{
        max-width: 1000px;
        margin: 20px auto;
        padding: 30px 20px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
        opacity: 0;
        transform: translateY(20px);
        animation: fadeInUp 1s ease-in-out forwards;
        

        h2, h3, blockquote{
          color: #0b1459;
          text-align: center;
          margin-top: 0;
          margin-bottom: 10px;
        }

        .quote{
          font-size: 24px;
          font-weight: bold;
        }
      }  
      
      @keyframes fadeInUp {
        from {
          opacity: 0;
          transform: translateY(20px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      } 
      
      .contact-center{
        max-width: 1000px;
        margin: 20px auto;
        padding: 20px;
        background: white;
        color: #0b1459;
        justify-content: space-between;
        text-align: center;
        border-radius: 12px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        
          h2 {
            font-size: 2rem;
            margin-bottom: 16px;
            margin-top: 0;
            margin-bottom: 10px;
            text-align: center;
          }

          p {
            font-size: 1rem;
            margin-bottom: 20px;
            line-height: 1.6;
          }

          .contact-center {
            max-width: 800px;
            margin: 40px auto;
            padding: 30px;
            background: white;
            color: #0b1459;
            text-align: center;
            border-radius: 12px;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            flex-wrap: wrap;
            justify-content: center;
          }

          .contact-wrapper {
            display: flex; 
            justify-content: center; 
            align-items: flex-start; 
            gap: 40px; 
            flex-wrap: wrap;
          }
          
          .contact-left{
            flex: 0 1 350px;
            text-align: left;
          }
          .contact-right {
            max-width: 350px;
            word-wrap: break-word; 
          }
          
          ul {
            padding: 0px;
            margin: 0;
            
          }
          
          li { 
            display: flex;
            align-items: center;
            gap: 8px; 
          }
          
          a {
            color: #ffcc80;
            text-decoration: none;
            font-weight: bold;
            transition: color 0.3s ease-in-out;
          }
          
          a:hover {
            color: #ffe0b2;
          }
           

      }
}
@media screen and (max-width: $break-small) {
  .about-us {
    .main {
      width: 100%;
      height: 100%; 
      padding: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;

      video, img {
        width: 100%;
        height: auto;
        object-fit: cover;
      
      }

      .content {
        font-size: 20px;  
        text-align: center;
      }
    }

    .background {
      .description {
        padding: 15px;

        h2 {
          font-size: 23px !important;
          text-align: center;
        }

        p {
          font-size: 10px !important;
          line-height: 1.6;
          text-align: justify;
        }

        .video-box {
          iframe {
            width: 80%;
            height: 200px;
            border-radius: 8px;
            object-fit: cover;
          }
        }
      }
    }

    .services {
      padding: 10px;

      h2 {
        font-size: 23px;
        text-align: center;
      }

      .service-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        gap: 20px;
        justify-content: center;
        padding: 10px;
      }

      .service-box {
        max-width: 65%;
        height: 90%;
        padding: 15px;
        text-align: center;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        
        
        .service-image {
          width: 30px;
          height: auto;
        }
        h3 {
          font-size: 13px;
          margin-bottom: 10px;
          margin-top: 0;
          margin-bottom: 10px;
        }
    
        p {
          font-size: 10px;
        }
        
        
      }
    }

    .more-info {
      flex-direction: column;
      text-align: center;
      padding: 15px;

      .info-image {
        max-width: 200px;
        margin: 0 auto 10px auto;
      }

      .info-text {
        h2 {
          font-size: 23px;
        }

        p {
          font-size: 10px;
          line-height: 1.5;
        }
        ul {
          li{
            font-size: 10px;
            margin-left: 10px;
          }
        }
      }
    }

    .visi-misi {
      padding: 10px;
      h2{
        font-size: 20px !important;
      }
      h3, blockquote{
        color: #0b1459;
        text-align: center;
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 10px !important;
      }

      .quote {
        font-size: 10px !important;
        text-align: center;
      }
    }

    .contact-center {
      text-align: center;
      justify-content: center;
      padding: 15px;

      h2{
        font-size: 20px;
      }
      p {
        font-size: 10px !important;
        line-height: 1.5;
      }

      .contact-wrapper {
        justify-content: center !important;
        text-align: center !important;
        gap: 10px; 
        flex-wrap: wrap;
        font-size: 10px;

        .contact-left{
          margin-left: 30px;
          margin-bottom: 10px;
        }
      }
      
    }
     
    
    
    
    
  }
}
@media (max-width: $break-medium) {
  .about-us {
    .main {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center; 
      padding: 0px;
     

      video {
        width: 100%;
        object-fit: cover; 
        
      }

      .content {
        font-size: 18px;
        text-align: center;
        position: absolute; 
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }
  }
}
